import React, { useEffect, useState } from 'react';
import './shared.css';
import { useNavigate } from 'react-router-dom';
import Logo from './Images/Citro_logo.svg';
import ReactGA from 'react-ga4';
import { goals } from '../Config/settings.js';


function EntryPage() {
const [goal, setGoal] = useState('');
const navigate = useNavigate();
const [isSubmitting, setIsSubmitting] = useState(false);
const queryParams = new URLSearchParams(window.location.search);
const cdrid = queryParams.get('cdrid');
const existingValue = queryParams.get('existing') || 'false'; 

useEffect(() => {
console.log('welcome to rewards optimiser');
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);


const handleSelectGoal = (goal) => {
const externalId = queryParams.get('externalId') || '';
setIsSubmitting(true);
navigate('/WeCanHelp', {state: {externalId: externalId, goal: goal}});
        
};

         


return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
    <h1 className="title">What is your primary money goal?</h1>
  <div className="card-box-container">
      {goals.map((goal, index) => (
        <div
          key={index}
          className={`card-box`}
          onClick={() => handleSelectGoal(goal)}
        >
          I want to {goal}
        </div>
      ))}
    </div>
    </div>



</div>

)
}

export default EntryPage;