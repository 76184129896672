import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import './shared.css';
import Logo from './Images/Citro_logo.svg';
import RegisterConsent from './Forms/RegisterConsent';
import Lock from './Images/lock.png';

function LaunchConsent() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');
  
    
return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
           </div>
  
  <div className="header">
  {<img src={Lock} alt="Logo" className="hero-image" />}   
    <h1 className="title">Last step: Connect with your bank</h1>
     
   
    <p className="subtitle"> Simply connect with your bank
on the next screen and we will do the
calculations to tell you how many dollars
you could have saved with Citro.
​</p>
    <p className="subtitle">Many of our users save hundreds, if not
    thousands of dollars, with Citro.​</p>
  </div>
  

  <RegisterConsent cdrid={cdrid}/>
</div>

)
}

export default LaunchConsent;